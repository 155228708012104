<template>

  <!-- Content Parameters-->
  <div v-if="!isStyle && !isFormat" >

    <course-contet-dialog
        v-if="displayContentDialog"
        v-model="displayContentDialog"
        @update="element.config.restricted_components = $event; updateSettings()"
        @edit-plan="editPlan"
        :course="course"
        :plan="selectedPaymentPlan"
        :restrictions="element.config.restricted_components"
    />
    <a-parameter-select
        class="mt-4"
        @input="updateSettings"
        v-model="element.config.course"
        :items="$store.state.avalon.coursesSelect"
        label="Course"
        :label-color="wsACCENT"
        :value-color="wsACCENT"
        null-text="Auto"
        label-top
        clearable
    />
    <v-divider
        class="mt-3"
        :style="`border-color : ${wsDARKLIGHT}`"
    />

    <div v-if="element.config.course">
      <h4 :style="`color : ${wsACCENT}`"  style="font-size: 14px" class="mt-6 mb-2">
        {{ $t('CoursePaymentPlans') }}
      </h4>
      <block-settings-navigation
          @select="openContentDialog"
          :items="navigationSelect"
          :return-action="selectedItemIndex !== null ? () => { selectedItemIndex = null ; selectedContentCategory='items'  } : null"
          no-input
      >
      </block-settings-navigation>
      <h5 :style="`color : ${wsACCENT}`" class="mt-4 font-weight-regular">
        {{ $t('avalon.course-content.edit_plans_description') }}
      </h5>
    </div>
    <div v-else>
      <h5 :style="`color : ${wsACCENT}`" class="mt-4 font-weight-regular">
        {{ $t('avalon.course-content.auto_description') }}
      </h5>
    </div>

  </div>
  <!-- Style Parameters-->
  <div v-else-if="isStyle">
    <ws-accordion
        :items="styleNavigationSelect"
    >
      <template #item.color>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            :block-style="blockStyle"
        />
      </template>
      <template #item.font>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            fonts
        />
      </template>
    </ws-accordion>
  </div>
  <!-- Format Parameters-->
  <div v-else-if="isFormat">

    <template  v-if="(element.config.style || 'rows') === 'rows'">
      <h5>{{ $t('avalon.list.rows.proportion') }}</h5>
      <ws-button-group
          @input="updateSettings"
          v-model="element.config.list_proportion"
          :items="orientationSelect"
          class="mb-2 mt-2"
      />
    </template>
    <template  v-if="(element.config.style || 'rows').includes('grid')">
      <h5>{{ $t('NumberOfColumns') }}</h5>
      <ws-button-group
          @input="updateSettings"
          v-model="element.config.grid_columns"
          :items="girIconsRowsSelect"
          class="mb-2 mt-2"
      />
    </template>

    <a-parameter-switch
        class="mt-6 "
        v-model="element.config.single_column"
        @input="updateSettings"
        label="SingleColumn"
        light
    />

    <a-parameter-slider
        @change="updateSettings"
        class="mt-4"
        v-model="element.config.thickness"
        :default="0"
        :value-color="wsACCENT"
        label="LineWidth"
        light
        min="0"
        max="3"
    />
    <a-parameter-slider
        v-if="!(element.config.style || 'rows').includes('grid') || element.config.grid_columns > 1"
        @change="updateSettings"
        class="mt-4"
        v-model="element.config.gutter"
        :default="0"
        :value-color="wsACCENT"
        label="avalon.list.rows.horizontal_padding"
        light
        min="0"
        max="100"
    />
    <a-parameter-slider
        @change="$emit('input' , this.COPY(this.element.config) )"
        class="mt-4"
        v-model="element.config.gutter_vertical"
        :default="0"
        :value-color="wsACCENT"
        light
        label="avalon.list.rows.vertical_padding"
        min="0"
        max="120"
    />

  </div>

</template>

<script>

import courseContetDialog from "@/components/AvalonEditor/Dialogs/elements/course/courseContetDialog";
import elements_design_config from "@/assets/json/avalon/elements_design_config.json"
import blockSettingsNavigation from "@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation";
import elementStyleSettings from "@/components/AvalonEditor/Dialogs/elements/elementStyleSettings";

import {mapActions} from "vuex";
export default {
  name: "avalonCourseContentSettings",
  components : {
    courseContetDialog,
    blockSettingsNavigation,
    elementStyleSettings
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    isFormat : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      selectedContentCategory : null,
      selectedItemIndex : null,
      config : {},
      drag : false,
      designSelect : [],
      courseData : {},
      displayContentDialog : false,
      selectedPaymentPlan : null
    }
  },
  computed : {
    restrictedPlans() {
      return this.element.config.restricted_plans || []
    },
    configCourseId() {
      return this.element.config.course || null
    },
    course() {
      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null
      if (!this.element.config.course && this.$store.state.avalonPage.course ) {
        return this.$store.state.avalonPage.course
      }
      if ( checkId === this.element.config.course ) {
        return this.$store.state.avalonPage.course
      }
      return this.courseData
    },

    styleNavigationSelect() {
      return [
        { name : this.$t('Color') , value : 'color' },
        { name : this.$t('Fonts') , value : 'font' }
      ]
    },
    navigationSelect() {
      if ( !this.course ) {
        return []
      }

      let items = []
      if ( !this.course.has_payment_plans ) {
        items =  [
          { text: this.$t('CourseContent'), value: 'all' }
        ]
      } else {

        if ( !this.course.payment_plans ) {
          return []
        }
        this.course.payment_plans.forEach(plan => {
          plan.icon = this.restrictedPlans.includes(plan.value) ?  'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked-outline'
          items.push(plan)
        })
      }

      return items
    },
    orientationSelect() {
      return [
        { text : '1 : 1' , value : '1-1' },
        { text : '2 : 3' , value : '2-3' },
      ]
    },
    girIconsRowsSelect() {
      return [
        { text : '1' , value : 1 },
        { text : '2' , value : 2 },
      ]
    },
    girIconsNumbersSelecr() {
      return [
        { text : '1' , value : 1 },
        { text : '2' , value : 2 },
        { text : '3' , value : 3 },
        { text : '4' , value : 4 },
      ]
    }
  },
  watch : {
    configCourseId(value , old) {

      if ( old && old !== value ) {
        this.element.config.restricted_components = {}
        this.element.config.restricted_plans = []
        this.updateSettings()
      }

      this.handleCourse()
    }
  },
  methods : {
    ...mapActions('courses', ['GET_COURSE_EDIT']),

    openContentDialog(id) {
      this.selectedPaymentPlan = id
      this.displayContentDialog = true
    },
    addItem() {
      this.element.config.elements.push({
        title : this.$t('EnterTitle'),
        text : this.$t('EnterText')
      })
    },
    editPlan({ plan, value }) {
      if ( !this.element.config.restricted_plans ) {
        this.element.config.restricted_plans = []
      }
      if ( value ) {
        this.element.config.restricted_plans = this.element.config.restricted_plans.filter( el => el !== plan )
      } else {
        if (!this.element.config.restricted_plans.includes(plan))  {
          this.element.config.restricted_plans.push(plan)
        }
      }
      this.updateSettings()
    },
    updateSettings() {
      console.log('updateSettings' , this.element)
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
    async handleCourse() {
      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null
      if ( this.element.config.course !== checkId ) {
        let data = {
          uuid : this.element.config.course,
          lang : this.$store.state.avalon.selectedLang
        }
        let result = await this.GET_COURSE_EDIT(data)
        if ( !result ) {
          return
        }
        this.courseData = result
      }
    }
  },
  async mounted() {
    this.designSelect =  elements_design_config
    this.config = this.COPY(this.value)
    await this.handleCourse()
  }

}
</script>

<style scoped>
</style>