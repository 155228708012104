<template>
  <ws-dialog
      v-model="display"
      width="741"
      title="asf"
      @save="saveChanges"

  >
    <template #header >

      <div class="d-flex justify-space-between align-center pt-6 px-9">
        <h3 class=""> {{ title }}</h3>
        <v-btn @click="display=false" icon :color="wsDARKER"> <v-icon>mdi-close</v-icon> </v-btn>
      </div>

      <div class="px-6 mt-2">
        <ws-check-box
            @input="editAll($event)"
            :selected-color="wsACCENT"
            class="mt-1"
            :value="getValueAll()"
            :semi-selected="getValueAll(true)"
            label="module"
        >
          <h5 :style="`color : ${wsACCENT}`" class="ml-n1 py-5"  >
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
            {{ $t(getValueAll() ? 'UnselectAll' : 'SelectAll') }}
          </h5>
        </ws-check-box>
      </div>
      <v-divider class="mt-n1 mb-2" :style="`border-color : ${wsBACKGROUND}`" />
    </template>

    <div v-for="(module,i) in content" :key="i" class="py-2">
<!-- Module-->
      <ws-check-box
          @input="editModule($event, i)"
          :selected-color="wsACCENT" class="mt-1"
          :value="getModuleValue(i)"
          :semi-selected="getModuleValue(i , true)"
      >
        <h3 class="ml-n1"  >
          <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          {{ module.name }}
        </h3>
      </ws-check-box>
<!-- Topics -->
      <div v-for="(topic,j) in module.topics" :key="i + j + 'topic'" class="py-2" >
        <ws-check-box
            @input="editTopic($event, j,i)"
            :value="getTopicValue(j,i)"
            :selected-color="wsACCENT"
            :semi-selected="getTopicValue(j,i , true)"
            class="mt-2"

        >
          <h4 class="ml-n1" >
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
            {{ topic.name }}
          </h4>
        </ws-check-box>
<!-- Components -->
        <v-sheet
            v-for="(component,k) in topic.components" :key="i + j + k + 'component'"
            :style="`border : 1px solid ${wsBACKGROUND}`"
            class="py-3 wsRoundedLight mt-3"
        >
          <ws-check-box
              @input="editComponent($event, component.uuid)"
              :value="getComponentValue(component.uuid)"
              :selected-color="wsACCENT"
              label="module"
          >
            <h4 class=" font-weight-regular" style="font-size: 16px" >
              <v-icon :color="wsATTENTION">{{ getComponentIcon(component)}}</v-icon>
              {{ component.name }}
            </h4>
          </ws-check-box>

        </v-sheet>

      </div>

    </div>
  </ws-dialog>
</template>

<script>
export default {
  name: "courseContentDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    course : {
      type : Object,
      default() { return {} }
    },
    plan : {
      type : String
    },
    restrictions : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      display : false,
      content : [],
      buffer : {}
    }
  },
  computed : {
    title() {
      let text = this.$t('avalon.page.editor.course.content.title')
      if ( this.planName  ) {
        text = this.$t('avalon.page.editor.course.content.payment_plan_title') + ' : ' + this.planName
      }
      return text
    },
    planName() {
      if ( !this.plan ) {
        return ''
      }
      if ( this.plan === 'all' ) {
        return ''
      }
      if ( !this.course.payment_plans ) {
        return ''
      }

      let plan = this.course.payment_plans.find(el => el.value === this.plan)
      if ( !plan ) {
        return ''
      }
      return plan.text
    }
  },
  watch : {
    display() {
      if (this.display !== this.value ) {
        this.$emit('input', this.display)
      }
    },
    value() {
      if (this.display !== this.value ) {
        this.display = this.value
      }
    }
  },
  methods : {

    saveChanges() {
      this.$emit('update' , this.buffer )
      this.notify(this.$t('ChangesSaved'))
      let value = this.getValueAll()
      let semiValue = this.getValueAll(true)
      if (!value) {
        value = semiValue
      }
      this.$emit('edit-plan' , { plan : this.plan , value : value } )
      this.display = false
    },

    // Get Values
    getValueAll(semiSelect) {

      let ids = []
      this.content.forEach(module => {
        module.topics.forEach(topic => {
          topic.components.forEach(component => {
            ids.push(component.uuid )
          })
        })
      })
      let check = ids.filter( el=> this.buffer[this.plan].includes(el) )

      if ( semiSelect ) {
        return check.length > 0 && check.length < ids.length
      }

      return check.length === 0
    },
    getModuleValue(moduleIndex , semiSelected = false) {

      if ( semiSelected ) {
        let componentIds = []
        let moduleComponentsIds = this.content[moduleIndex].topics
            .map(el => el.components.map(el => el.uuid) )

        moduleComponentsIds.forEach(topicArray => {
          topicArray.forEach(copmonentId => {
            componentIds.push(copmonentId)
          })
        })

        let check = componentIds.filter( el => this.buffer[this.plan].includes(el) )
        return check.length > 0 && check.length < componentIds.length
      }

      let check = this.content[moduleIndex].topics
          .map( (el,index) => { return { uuid : el.uuid, index } } )
          .filter(el => !this.getTopicValue( el.index , moduleIndex) )

      return check.length === 0
    },
    getTopicValue(topicIndex, moduleIndex , semiSelected = false ) {
      let check = this.content[moduleIndex].topics[topicIndex].components.map(el => el.uuid).filter( el => this.buffer[this.plan].includes(el))

      if ( semiSelected    ) {
        return check.length > 0 && check.length < this.content[moduleIndex].topics[topicIndex].components.length
      }

      return check.length === 0
    },
    getComponentValue(componentId) {
      if (!this.buffer ) {
        return false
      }
      if ( !this.buffer[this.plan] ) {
        return false
      }
      return !this.buffer[this.plan].includes(componentId)
    },

    // Edit values
    editAll(value) {

      if ( value ) {
        this.buffer[this.plan] = []
      } else {
        let ids = []
        this.content.forEach(module => {
          module.topics.forEach(topic => {
            topic.components.forEach(component => {
              ids.push(component.uuid )
            })
          })
        })
        this.buffer[this.plan] = this.COPY(ids)
        this.buffer = this.COPY(this.buffer)
      }
    },
    editModule(value , moduleIndex) {

      let buffer = this.COPY(this.buffer)

      let componentIds = []
      let moduleComponentsIds = this.content[moduleIndex].topics
          .map(el => el.components.map(el => el.uuid) )

      moduleComponentsIds.forEach(topicArray => {
        topicArray.forEach(copmonentId => {
          componentIds.push(copmonentId)
        })
      })

      if ( value ) {
        buffer[this.plan] = buffer[this.plan].filter( el =>  !componentIds.includes(el) )
      }
      else {
        componentIds.forEach(componentId => {
          if ( !buffer[this.plan].includes(componentId) ) {
            buffer[this.plan].push(componentId)
          }
        })
      }

      this.buffer = buffer

    },
    editTopic(value , topicIndex, moduleIndex ) {
      let buffer = this.COPY(this.buffer)

      let topicComponentsIds = this.content[moduleIndex].topics[topicIndex].components.map(el => el.uuid)

      if ( value ) {
        buffer[this.plan] = buffer[this.plan].filter( el =>  !topicComponentsIds.includes(el) )
      } else {
        topicComponentsIds.forEach(componentId => {
          if ( !buffer[this.plan].includes(componentId) ) {
            buffer[this.plan].push(componentId)
          }
        })
      }

      this.buffer = buffer

    },
    editComponent(value , id) {

      this.preparePermissionsConfig()

      if ( value ) {
        this.buffer[this.plan] = this.buffer[this.plan].filter( el => el !== id)
      } else {
        if ( !this.buffer[this.plan].includes(id) ) {
          this.buffer[this.plan].push(id)
        }
      }
      this.buffer = this.COPY(this.buffer)
    },

    // Technical
    getComponentIcon(type) {
      switch(type) {
        case 'lecture' : return 'mdi-account'
        default : return 'mdi-cog'
      }
    },
    getContent() {

      let items = []

      items = this.COPY( this.course.modules)

      if ( this.plan && this.plan !== 'all' ) {

        this.course.modules.forEach((module , m) => {
          module.topics.forEach((topic, t) => {
            items[m].topics[t].components = items[m].topics[t].components.filter(el=> el.payment_plan_id && el.payment_plan_id.includes(this.plan))
          })
        })

        this.course.modules.forEach((module , m) => {
          items[m].topics = items[m].topics.filter(el=> el.components.length > 0)
        })

        items = items.filter( el => el.topics.length > 0 )

      }

      this.content =  items
    },
    preparePermissionsConfig( firstCheck ) {
      if ( firstCheck ) {
        this.buffer = this.COPY( this.restrictions )
      }

      if (!this.buffer || Array.isArray(this.buffer)  ) {
        this.buffer = {}
        this.$emit('update' , this.buffer )
      }
      if ( !this.buffer[this.plan] ) {
        this.buffer[this.plan] = []
        this.$emit('update' , this.buffer )
      }
    }
  },
  beforeMount() {
    this.preparePermissionsConfig(true)
  },
  mounted() {
    this.display = this.value || false
    this.getContent()
  }
}
</script>

<style scoped>

</style>